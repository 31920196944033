/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { MaintenanceWrapper } from 'components/maintenance-wrapper';
import { IS_DEPOSIT_MAINTENANCE } from 'constants/general';
import { GiftCardIcon, MetamaskIcon } from 'icons';
import { Suspense, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrenciesList } from 'services/user/components/currencies-list';
import { WALLETS_TABS } from 'services/user/modules/currencies';
import { DepositView } from 'services/user/pages/wallet/components/deposit-view';
import { GiftCardView } from 'services/user/pages/wallet/components/gift-card-view';
import { Web3WalletView } from 'services/user/pages/wallet/components/web3-wallet-view';
import { Withdraw } from 'services/user/pages/wallet/components/withdraw';

export const Wallet = ({ isWithdrawal, shortcut }) => {
  const { t } = useTranslation();
  const LIST = useMemo(
    () => [
      // {
      //   iconContent: (
      //     <Flex gap="1">
      //       <Image
      //         h="14px"
      //         src="https://spinkick.imgix.net/footer-logos/visa.svg"
      //         alt="Visa"
      //         draggable="false"
      //       />
      //       <Image
      //         h="14px"
      //         src="https://spinkick.imgix.net/footer-logos/mastercard.svg"
      //         alt="Mastercard"
      //         draggable="false"
      //       />
      //     </Flex>
      //   ),
      //   name: (
      //     <Flex as="span" gap="2" alignItems="center">
      //       {t('wallet.credit-card')}
      //     </Flex>
      //   ),
      //   shortcut: 'USD',
      // },
      {
        Icon: MetamaskIcon,
        name: (
          <Flex as="span" gap="2" alignItems="center">
            {t('wallet.web3')}
          </Flex>
        ),
        shortcut: 'WEB3',
      },
      ...WALLETS_TABS,
      {
        Icon: GiftCardIcon,
        name: t('wallet.gift'),
        shortcut: 'GIFT',
      },
    ],
    [t],
  );

  const [currencyTabIndex, setCurrencyTabIndex] = useState(2);

  useLayoutEffect(() => {
    if (shortcut) {
      const propIndex = LIST.findIndex(item => item.shortcut === shortcut);
      if (propIndex !== -1) {
        setCurrencyTabIndex(propIndex);
      }
    }
  }, [shortcut, LIST]);

  const activeCurrencyTab = LIST[currencyTabIndex];

  const renderView = () => {
    // if (activeCurrencyTab.shortcut === 'USD') {
    //   return (
    //     <Suspense fallback={<Loading pos="relative" my="14" />}>
    //       <CardView />
    //     </Suspense>
    //   );
    // }
    if (activeCurrencyTab.shortcut === 'GIFT') {
      return <GiftCardView />;
    }
    if (activeCurrencyTab.shortcut === 'WEB3') {
      return (
        <Suspense fallback={<Loading pos="relative" my="14" />}>
          <Web3WalletView />
        </Suspense>
      );
    }
    return <DepositView key={currencyTabIndex} {...activeCurrencyTab} />;
  };

  const content = (
    <Content w="full" maxW="6xl" mx="auto" flexGrow="1">
      <Flex flexDirection="column">
        <Flex
          gap={{ base: '5', xl: '14' }}
          align="start"
          flexDir={{ base: 'column-reverse', xl: 'row' }}
        >
          <Box w="full">
            {!isWithdrawal && (
              <MaintenanceWrapper isActive={IS_DEPOSIT_MAINTENANCE}>
                <>
                  <CurrenciesList
                    active={currencyTabIndex}
                    onSelect={setCurrencyTabIndex}
                    list={LIST}
                  >
                    {renderView()}
                  </CurrenciesList>
                </>
              </MaintenanceWrapper>
            )}
            {isWithdrawal && <Withdraw shortcut={activeCurrencyTab.shortcut} />}
          </Box>
        </Flex>
      </Flex>
    </Content>
  );

  return (
    <Flex direction="column" flexGrow="1">
      {content}
    </Flex>
  );
};
