import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Accordion } from 'services/cms/components/html-content/components/accordion';
import { RaceTable } from 'services/games/components/race-table';
import { TournamentsSlider } from 'services/games/components/tournaments-slider';
import { useTournamentPrize } from 'services/games/hooks/use-tournament-prize';
import {
  TOURNAMENT_CONTENT_BY_TYPE,
  getTournamentsIsLoaded,
  getTournamentsList,
  joinTournament,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { getUserName } from 'services/user/modules/user';
import { useActions } from 'store';
import { PromoBanner } from '../casino/components/promo-banner';
import { Wagers } from '../casino/components/wagers';
import { Details } from './components/details';

const feType = 'DOWNBUTNOTOUT';

export const DownButNotOut = () => {
  const isLoaded = useSelector(getTournamentsIsLoaded);

  const actions = useActions({ loadTournaments, joinTournament });
  const tournaments = useSelector(getTournamentsList);

  const tournament = tournaments?.find(tour => tour?.feType === feType);

  const actualPrize = useTournamentPrize(!!tournament, tournament || {});
  const username = useSelector(getUserName);

  const { t } = useTranslation();

  useEffect(() => {
    actions.loadTournaments();
  }, [actions]);

  if (!isLoaded) {
    return <Loading />;
  }

  if (!tournament) {
    return <Navigate to="/" />;
  }

  const {
    entries: tournamentEntries = [],
    maxWinnersPerRound,

    rank,
    score,
  } = tournament;

  const { isMultiplier } = TOURNAMENT_CONTENT_BY_TYPE[feType];

  let entries = tournamentEntries;

  if (rank && rank > maxWinnersPerRound) {
    entries = entries.concat([
      { prize: '0', rank, score, user: { name: username } },
    ]);
  }

  return (
    <Content w="full">
      <Content w="full">
        <Box p="5" maxW="6xl" w="full" mx="auto">
          <PromoBanner />
          <Flex
            mt="7"
            gap={{ base: '5', md: '14' }}
            align="start"
            flexDir={{ base: 'column-reverse', md: 'row' }}
          >
            <Box w="full" mx="auto">
              <Details />
            </Box>
          </Flex>
          <Box mt="7">
            <RaceTable
              overlayMessage="Winners drawn when the Tournament ends"
              entries={entries}
              isMultiplier={isMultiplier}
              currentPrizePool={actualPrize}
              maxWinnersPerRound={maxWinnersPerRound}
              feType={feType} // Pass the feType prop here
            />
          </Box>
        </Box>
      </Content>
      <Accordion
        title={t('tournaments.terms')}
        options={[
          'Points are calculated based on the amount of bets you place that result in an outcome of 0x. For every 0x outcome, you receive 1 point.',
          'In the event of a tie, the player with the highest value of total losses will be awarded the position.',
          'The Down But Not Out Tournament will run for 30 days.',
          'Players are automatically opted into the Down But Not Out Tournament.',
          'A minimum bet requirement of $0.60 applies. Bets less than $0.60 will not contribute toward the Tournament.',
          'Only real money wagers qualify. Bonus money balance is excluded from wagering.',
          'By opting in, the player accepts to receive emails with any winnings from this campaign.',
          "If a player is involved in any suspicious or fraudulent activity, Sherbet reserves the right to undertake a KYC process, cancel the player's bets, remove the player from the current promotion, exclude them from all future promotions, and not credit any winnings.",
          'Should Sherbet detect fraud, foul play, manipulation of the promotion rules, or abuse of the promotion, the relevant player and their related accounts will be suspended from current and future promotions.',
          "These terms and conditions are in addition to Sherbet's TOS.",
        ]}
      />
      <Box borderTop="1px solid" borderColor="truffle-border">
        <Flex
          p="5"
          w="full"
          maxW="6xl"
          mx="auto"
          flexGrow="1"
          gap="7"
          flexDir="column"
        >
          <TournamentsSlider />
        </Flex>
      </Box>
      <Wagers />
    </Content>
  );
};
