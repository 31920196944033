import { Box } from '@chakra-ui/react';
import styles from 'services/cms/components/html-content/style.module.scss';

export const Details = () => (
  <Box className={styles.content}>
    <h2>Introducing Sherbet's $50K Down But Not Out Promotion</h2>
    <p>
      Have you ever felt the sting of a 0x? Sherbet is turning the tables on bad
      luck this month with a new promotion: Down But Not Out. For every bet you
      place on the casino that results in a 0x, you'll earn 1 point. The more
      points you accumulate, the higher your position in the leaderboard.
    </p>
    <h2>How to Win</h2>
    <ul>
      <li>
        Place bets of $0.60 USD or more on any casino games across Sherbet.
      </li>
      <li>
        You earn a point on the leaderboard every time you hit a 0x across our
        casino games with a minimum bet size of $0.60 or more.
      </li>
      <li>Finish in the top ten to win a share of $50,000 USD.</li>
    </ul>
    <p style={{ marginTop: '-4px' }}>
      The $50,000 prize pool is split amongst winners based on their leaderboard
      position and automatically distributed as withdrawable balance.
    </p>
  </Box>
);
