import { Box, Flex, Heading } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Seo } from 'components/seo';
import { SeoFooter } from 'components/seo-footer';
import { formatCredit } from 'helpers/numeral';
import { convertToOriginal } from 'helpers/url-text';
import { useLayout } from 'hooks/use-layout';
import { CategoriesIcon } from 'icons';
import { UnverifiedNotification } from 'layout/layout/components/unverified-notification';
import { Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BlogSlider } from 'services/cms/components/blog-slider';
import { PromoSlider } from 'services/cms/components/promo-slider';
import { CasinoSearch } from 'services/games/components/casino-search/casino-search';
import { PopularWithFriendsSlider } from 'services/games/components/popular-with-friends-slider';
import { SlotsGrid } from 'services/games/components/slots-grid';
import { VersusSlider } from 'services/games/components/versus-slider';
import {
  getSlotsFavoritesGamesList,
  getSlotsFriendsPopularGames,
  getSlotsGamesList,
  getSlotsIsLoaded,
  getSlotsPlaylists,
  getSlotsPopularGames,
  getSlotsRecentGamesList,
  getSlotsRecommendationsUserLobbyList,
  getSlotsTopGamesList,
} from 'services/games/modules/slots';
import { Wagers } from 'services/games/pages/casino/components/wagers';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { Skeleton } from 'uikit/skeleton';
import { GameShowsSlider } from './components/game-shows-slider';
import { LiveCasinoSlider } from './components/live-casino-slider';
import { MegawaysSlider } from './components/megaways-slider';
import { NewReleasesSlider } from './components/new-releases-slider';
import { OriginalsSlider } from './components/originals-slider';
import { PromoBanner } from './components/promo-banner';
import { ProvidersGrid } from './components/providers-grid';
import { ProvidersSlider } from './components/providers-slider';
import { RecentlyPlayedSlider } from './components/recently-played-slider';
import { SeoContent } from './components/seo-content';
import { SlotsSlider } from './components/slots-slider';
import { KickStyleStreamSection } from './KickStyleStreamSection';

const metaNewReleases = { isNewReleases: true };

type Props = {
  isRecentLoaded?: boolean;
  isFavoritesLoaded?: boolean;
  isProvidersList?: boolean;
};

const CATEGORY_MAPPINGS: { [key: string]: string[] } = {
  blackjack: ['blackjack', 'live-blackjack'],
  roulette: ['roulette', 'live-roulette'],
  baccarat: ['baccarat', 'live-baccarat'],
  slots: ['slots', 'video-slots'],
  'game-shows': [
    'game-shows',
    'live-game-shows',
    'live-wheel-of-fortune',
    'live-dice',
  ],
};

export const Casino: React.FC<Props> = ({
  isProvidersList = false,
  isRecentLoaded,
  isFavoritesLoaded,
}) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('featured');
  const [providers, setProviders] = useState<string[]>([]);
  const popularGames = useSelector(getSlotsPopularGames);
  const populaFriendsGames = useSelector(getSlotsFriendsPopularGames);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const topGames = useSelector(getSlotsTopGamesList);
  const recentGames = useSelector(getSlotsRecentGamesList);
  const recommendedGames = useSelector(getSlotsRecommendationsUserLobbyList);
  const favoritesGames = useSelector(getSlotsFavoritesGamesList);
  const games = useSelector(getSlotsGamesList);
  const { category, provider, tag, playlist } = useParams();
  const { t } = useTranslation();
  const isLoaded = useSelector(getSlotsIsLoaded);
  const isValidSearch = search.length > 2;
  const playlists = useSelector(getSlotsPlaylists);
  // @ts-ignore
  const playlistData = playlists[playlist];
  const isLobby =
    !category &&
    !provider &&
    !tag &&
    !isProvidersList &&
    !playlist &&
    !isValidSearch;
  const { isMobile } = useLayout();

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useLayoutEffect(() => {
    setSearch('');
  }, [category, provider, tag]);

  useEffect(() => {
    const handleClearSearch = () => {
      setSearch('');
    };

    window.addEventListener('clearCasinoSearch', handleClearSearch);

    return () => {
      window.removeEventListener('clearCasinoSearch', handleClearSearch);
    };
  }, []);

  const isDisabledFilters = ['recent', 'favourites'].includes(category || '');
  const hasProviders =
    !isLobby && !isProvidersList && (!!category || isValidSearch);
  const hasSort = !isDisabledFilters && !isLobby && !isProvidersList;

  const availableProviders = useMemo(() => {
    if (!isProvidersList && !isLobby) {
      const relevantGames = isValidSearch
        ? games.filter(
            game =>
              game.title?.toLowerCase().includes(search.toLowerCase()) ||
              game.provider?.toLowerCase().includes(search.toLowerCase()),
          )
        : games;

      const providerCounts = relevantGames.reduce(
        (acc, game) => {
          if (game.provider) {
            if (acc[game.provider]) {
              acc[game.provider] += 1;
            } else {
              acc[game.provider] = 1;
            }
          }
          return acc;
        },
        {} as Record<string, number>,
      );

      return Object.entries(providerCounts)
        .map(([key, value]) => ({
          title: key,
          count: value,
        }))
        .sort((a, b) => b.count - a.count);
    }
    return [];
  }, [games, isProvidersList, isLobby, isValidSearch, search]);

  const filteredGames = useMemo(() => {
    let list = games;
    if (isValidSearch) {
      list = games.filter(x => {
        const searchText = search.toLowerCase().trim();
        return (
          x.title?.toLowerCase().includes(searchText) ||
          x.provider?.toLowerCase().includes(searchText)
        );
      });
    } else if (category === 'recent') {
      list = recentGames;
    } else if (category === 'curated-for-you') {
      list = recommendedGames;
    } else if (category === 'favourites') {
      list = favoritesGames;
    } else if (category === 'popular') {
      list = popularGames;
    } else if (category === 'recent-wins') {
      list = topGames;
    } else if (category === 'popular-with-friends') {
      list = populaFriendsGames;
    } else if (category === 'new-releases') {
      list = games
        .filter(g => g.newRelease)
        .sort((a, b) => {
          if (a.featured === b.featured) return 0;
          return a.featured ? -1 : 1;
        });
    } else if (category === 'top-games') {
      list = topGames;
    } else if (category === 'all') {
      list = games;
    } else if (category) {
      const categoriesToFilter = CATEGORY_MAPPINGS[category] || [category];
      list = games.filter(g =>
        g.categories?.some(cat => categoriesToFilter.includes(cat)),
      );
      const featuredGames = list.filter(g => g.featured);
      const nonFeaturedGames = list.filter(g => !g.featured);
      list = [...featuredGames, ...nonFeaturedGames];
    } else if (provider) {
      list = games.filter(g => g.provider === provider);
    } else if (tag) {
      list = games.filter(g => g.tags?.includes(tag));
    } else if (playlist) {
      list = playlistData?.playlistGames || [];
    }

    if (providers.length) {
      list = list.filter(x => x.provider && providers.includes(x.provider));
    }

    if (!isDisabledFilters && !isProvidersList && !isLobby) {
      list = [...list].sort((a, b) => {
        switch (sort) {
          case 'alphabet':
            return (a.title || '').localeCompare(b.title || '');
          case 'popular':
            return (b.popularity || 0) - (a.popularity || 0);
          case 'alphabetZ':
            return (b.title || '').localeCompare(a.title || '');
          case 'featured':
            return (Number(b.featured) || 0) - (Number(a.featured) || 0);
          default:
            return 0;
        }
      });
    }

    return list.filter(game => game && game.image);
  }, [
    games,
    category,
    recommendedGames,
    provider,
    tag,
    recentGames,
    playlist,
    favoritesGames,
    popularGames,
    populaFriendsGames,
    topGames,
    search,
    playlistData?.playlistGames,
    isValidSearch,
    sort,
    providers,
    isDisabledFilters,
    isProvidersList,
    isLobby,
  ]);

  const isFilteredContent =
    category || provider || tag || playlist || isValidSearch;

  const content = (
    <Box key={String(isFilteredContent)}>
      {category && !t(`seo./casino/category/${category}`, '') && (
        <Seo
          title={t('seo.category.title', {
            category: t(
              `casino.category.${category}`,
              convertToOriginal(category),
            ),
          })}
        />
      )}
      {provider && (
        <Seo
          title={t('seo.provider.title', {
            provider: t(`casino.provider.${provider}`, provider),
          })}
        />
      )}
      {!isProvidersList && !isLobby && (
        <>
          <Flex justifyContent="space-between" alignItems="center" mb="3">
            <Skeleton
              isLoaded={
                isRecentLoaded !== undefined || isFavoritesLoaded !== undefined
                  ? isRecentLoaded || isFavoritesLoaded
                  : isLoaded
              }
            >
              <Flex align="center" gap="2">
                <CategoriesIcon color="candy-floss-text" />
                <Heading
                  lineHeight="32px"
                  noOfLines={1}
                  fontWeight="500"
                  fontSize="xl"
                >
                  {t('casino.results', {
                    results: formatCredit(filteredGames.length),
                  })}
                </Heading>
              </Flex>
            </Skeleton>
          </Flex>
          <SlotsGrid
            hasShowMore
            meta={category === 'new-releases' ? metaNewReleases : undefined}
            games={filteredGames}
            isLoaded={
              isRecentLoaded !== undefined ? isRecentLoaded : isFavoritesLoaded
            }
          />
        </>
      )}
      {isProvidersList && (
        <ProvidersGrid search={isValidSearch ? search : ''} />
      )}
    </Box>
  );

  return (
    <Content>
      <Flex
        p="5"
        w="full"
        maxW="6xl"
        mx="auto"
        flexGrow="1"
        gap="7"
        flexDir="column"
      >
        {isAuthorized && <UnverifiedNotification />}
        {isLobby && <PromoBanner />}
        <CasinoSearch
          search={search}
          onChangeSearch={setSearch}
          sort={sort}
          onChangeSort={setSort}
          providers={providers}
          onChangeProviders={setProviders}
          hasSort={hasSort}
          hasProviders={hasProviders}
          hasSearch
          availableProviders={availableProviders}
          placeholder={t('casino.search.placeholder')}
          hasLucky={false}
          isFullWidth={false}
          onFocus={() => setIsSearchFocused(true)}
          isOpened={isSearchFocused}
          autoFocus={false}
        />
        {isLobby ? (
          <Content gap="7">
            {isAuthorized && <RecentlyPlayedSlider />}
            <SlotsSlider />
            <OriginalsSlider />
            {isLobby && <PromoSlider />}
            <LiveCasinoSlider />
            <KickStyleStreamSection />
            <ProvidersSlider />
            {isAuthorized && <PopularWithFriendsSlider />}
            <GameShowsSlider />
            <VersusSlider />
            <MegawaysSlider />
            <NewReleasesSlider />
          </Content>
        ) : (
          content
        )}
      </Flex>
      <Wagers />
      <Box borderTop="1px solid" borderColor="truffle-border">
        <Flex
          p="5"
          w="full"
          maxW="6xl"
          mx="auto"
          flexGrow="1"
          gap="7"
          flexDir="column"
        >
          <BlogSlider />
        </Flex>
      </Box>
      {isLobby && (
        <SeoFooter>
          <Suspense fallback={<div />}>
            <SeoContent />
          </Suspense>
        </SeoFooter>
      )}
    </Content>
  );
};
