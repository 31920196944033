import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';

export const PromoBanner = () => (
  <Link
    to="/tournaments/down-but-not-out"
    transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
    transitionDuration=".15s"
    _hover={{
      transform: 'translateY(-0.125rem)',
      opacity: '0.9',
    }}
    _active={{
      transform: 'translateY(0px)',
    }}
    transitionProperty="all"
  >
    <Flex gap="3">
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        bgColor="sugar-dust"
        gap={{ base: '0', md: '5' }}
        w="full"
        rounded="xl"
        position="relative"
        bgPos="left"
        overflow={{ base: 'hidden', md: 'visible' }}
        bgSize="cover"
        bgImage={{
          base: 'url(https://spinkick.imgix.net/dbno/background-mobile.png)',
          md: "url('https://spinkick.imgix.net/dbno/background-wide.png')",
        }}
      >
        <Flex
          flexDirection="column"
          alignItems={{ base: 'center', md: 'start' }}
          textAlign={{ base: 'center', md: 'start' }}
          h={{ base: 'auto', md: 'full' }}
          p="5"
          pb={{ base: '0', md: '5' }}
          w="full"
        >
          <Box>
            <Box mb="1.5">
              <NotificationTip
                rounded="full"
                notifications="Promotion"
                bgColor="vanilla-text"
                color="#3f0978"
              />
            </Box>
            <Heading
              justifyContent={{ base: 'center', md: 'start' }}
              fontSize="2xl"
              textTransform="uppercase"
              fontWeight="700"
              letterSpacing="0.5px"
              display="flex"
              alignItems="center"
            >
              $50K Down But Not Out
            </Heading>
            <Text maxW="sm" noOfLines={1} opacity="0.9" mt="1">
              It pays to be unlucky on Sherbet this September
            </Text>
            <Box mt="5" position="relative">
              <Button
                colorScheme="base"
                bg="none"
                border="1px solid"
                borderColor="vanilla-text"
                rounded="full"
                color="vanilla-text"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Flex>
        <Box overflow="hidden" mt="-9" pointerEvents="none">
          <Box
            position="relative"
            w="full"
            h={{ base: '160px', md: 'auto' }}
            display={{ base: 'block', md: 'none' }}
          >
            <motion.img
              initial={{ x: -20, opacity: 1 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: 'easeOut' }}
              style={{
                left: '-40px',
                height: '160px',
                width: 'auto',
                bottom: '0',

                position: 'absolute',
              }}
              src="https://spinkick.imgix.net/dbno/red-man.png"
            />
            <motion.img
              initial={{ x: 20, opacity: 1 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: 'easeOut' }}
              style={{
                right: '-40px',
                height: '125px',
                width: 'auto',
                bottom: '0',
                position: 'absolute',
              }}
              src="https://spinkick.imgix.net/dbno/blue-man.png"
            />
          </Box>
        </Box>
        <Box
          pointerEvents="none"
          position="relative"
          w="full"
          display={{ base: 'none', md: 'block' }}
        >
          <motion.img
            initial={{ x: -50, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: 'easeOut' }}
            style={{
              left: '0',
              height: '188.8px',
              width: 'auto',
              bottom: '0',
              position: 'absolute',
            }}
            src="https://spinkick.imgix.net/dbno/red-man.png"
          />
          <motion.img
            initial={{ x: 20, opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: 'easeOut' }}
            style={{
              right: '20px',
              height: '145px',
              width: 'auto',
              bottom: '0',
              position: 'absolute',
            }}
            src="https://spinkick.imgix.net/dbno/blue-man.png"
          />
        </Box>
      </Flex>
    </Flex>
  </Link>
);
