import { Box } from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewGrid } from 'components/preview-grid';
import { QuestsIcon } from 'icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TournamentPreview } from 'services/games/components/tournament-preview';
import {
  getTournamentsIsLoaded,
  getTournamentsList,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { PromoBanner } from '../casino/components/promo-banner';

export const Tournaments = () => {
  const list = useSelector(getTournamentsList);
  const isLoaded = useSelector(getTournamentsIsLoaded);
  const actions = useActions({
    loadTournaments,
  });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadTournaments();
    }
  }, [actions, isLoaded]);

  // Filter out the Down But Not Out tournament
  const filteredList = list.filter(
    tournament => tournament.feType !== 'DOWNBUTNOTOUT',
  );

  return (
    <Content>
      <Box maxW="6xl" mx="auto" flexGrow="1" w="full" p="5">
        <Box mb="5">
          <PromoBanner />
        </Box>
        {isLoaded && !filteredList.length ? (
          <EmptyResults icon={QuestsIcon} />
        ) : (
          <PreviewGrid
            Item={TournamentPreview}
            list={filteredList}
            size="xl"
            skeletonCount={2}
            isLoaded={isLoaded}
          />
        )}
      </Box>
    </Content>
  );
};
